import React, { Component } from "react"
import { navigate } from "gatsby-link"

import * as ContactFormStyles from "./contactForm.module.css"
import Loader from "../loader"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const initialState = {
  email: {
    value: "",
    isDirty: false,
    isActive: false,
  },
  name: {
    value: "",
    isDirty: false,
    isActive: false,
  },
  subject: {
    value: "",
    isDirty: false,
    isActive: false,
  },
  message: {
    value: "",
    isDirty: false,
    isActive: false,
  },
  formIsValid: false,
  formIsLoading: false,
}

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.reset = this.reset.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFocusInput = this.handleFocusInput.bind(this)
  }

  componentDidMount() {
    if (this.props.subjectValue || this.props.messageValue) {
      this.setState({
        subject: {
          value: this.props.subjectValue || "",
          isDirty: true,
        },
        message: {
          value: this.props.messageValue || "",
          isDirty: true,
        },
      })
    }
  }

  handleFocusInput(event) {
    let name = event.target.name
    let toBeActive =
      this.state[name].isDirty || !this.state[name].isActive ? true : false

    if (this.state[name].isDirty && this.state[name].isActive) {
      return
    }

    this.setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        isActive: toBeActive,
      },
    }))
  }

  handleInputChange(event) {
    let value = event.target.value
    let name = event.target.name
    let isDirty = false

    if (value.length > 0) {
      isDirty = true
    }

    this.setState(
      {
        [name]: {
          value: value,
          isDirty: isDirty,
        },
      },
      () => {
        if (
          (this.state.email.isDirty &&
            this.state.name.isDirty &&
            this.state.subject.isDirty &&
            (this.props.messageRequired && this.state.message.isDirty)) ||
          (this.state.email.isDirty &&
            this.state.name.isDirty &&
            this.state.subject.isDirty &&
            !this.props.messageRequired)
        ) {
          this.setState({ formIsValid: true })
        } else {
          this.setState({ formIsValid: false })
        }
      }
    )
  }

  onSubmitHandler(event) {
    event.preventDefault()
    this.setState({ formIsLoading: true })

    let form = event.target
    let body = {
      name: this.state.name.value,
      subject: this.state.subject.value,
      email: this.state.email.value,
      message: this.state.message.value,
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...body,
      }),
    })
    .then(() => navigate(form.getAttribute("action")))
    .catch(error => {
      this.setState({ formIsLoading: false }, () => alert(error))
    })
  }

  reset() {
    this.setState(initialState)
  }

  render() {
    const { formIsLoading } = this.state

    return (
      <form
        className={ContactFormStyles.form}
        name="contact"
        action="/contact/success/"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.onSubmitHandler}
      >
        <label
          aria-label="Email*"
          data-text="Email*"
          className={
            this.state.email.isActive || this.state.email.isDirty
              ? ContactFormStyles.active
              : ""
          }
        >
          <input
            type="email"
            name="email"
            value={this.state.email.value}
            onChange={this.handleInputChange}
            onFocus={this.handleFocusInput}
            onBlur={this.handleFocusInput}
            autoComplete="email"
            isrequired="true"
          />
        </label>
        <label
          aria-label="Name*"
          data-text="Name*"
          className={
            this.state.name.isActive || this.state.name.isDirty
              ? ContactFormStyles.active
              : ""
          }
        >
          <input
            type="text"
            name="name"
            value={this.state.name.value}
            onChange={this.handleInputChange}
            onFocus={this.handleFocusInput}
            onBlur={this.handleFocusInput}
            autoComplete="name"
            isrequired="true"
          />
        </label>
        <label
          aria-label="Subject*"
          data-text="Subject*"
          className={
            this.state.subject.isActive || this.state.subject.isDirty
              ? ContactFormStyles.active
              : ""
          }
        >
          <input
            type="text"
            name="subject"
            value={this.state.subject.value}
            onChange={this.handleInputChange}
            onFocus={this.handleFocusInput}
            onBlur={this.handleFocusInput}
            autoComplete="nope"
            isrequired="true"
          />
        </label>
        <label
          aria-label={this.props.messageRequired ? "Message*" : "Message"}
          data-text={this.props.messageRequired ? "Message*" : "Message"}
          className={
            this.state.message.isActive || this.state.message.isDirty
              ? ContactFormStyles.active
              : ""
          }
        >
          <textarea
            type="text"
            name="message"
            rows={this.props.isShopModalForm ? "3" : "10"}
            value={this.state.message.value}
            onChange={this.handleInputChange}
            onFocus={this.handleFocusInput}
            onBlur={this.handleFocusInput}
            autoComplete="nope"
            isrequired={this.props.messageRequired ? "true" : "false"}
          />
        </label>
        <button
          className={ContactFormStyles.submitButton}
          disabled={!this.state.formIsValid}
          type="submit"
          aria-label={"form submit button"}
        >
          {formIsLoading ? "Sending..." : "Submit"}
        </button>

        {formIsLoading && <Loader />}
      </form>
    )
  }
}

export default ContactForm
